import * as React from "react"
import PropTypes from "prop-types"
import SiteNavigation from "../components/nav/nav"
import Footer from "../components/footer/footer"
import "./layout.scss"
import IEBanner from "../components/ie-banner/ie-banner"
import "@fontsource/ibm-plex-sans/400.css"
import "@fontsource/ibm-plex-sans/600.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/poppins/400.css"

const Layout = ({ children, nav }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  return (
    <>
      <IEBanner />
      {nav !== "hidden" && <SiteNavigation />}
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
